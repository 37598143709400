import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Layout from '../layouts/index';

const NotFoundPage = (props) => {

  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <Layout bodyClass="page-404">
      <div className="intro pb-4">
        <div className="container">
          <h1>Página no encontrada</h1>
          <p>La página que intentas solicitar no existe (Error 404). Prueba en estos links:</p>
            <ul>
              <li>
              <Link to="/">Inicio</Link>
              </li>
              {menuLinks.map(link => (
                <li key={link.name}>
                  <Link to={link.link}>{link.name}</Link>
                </li>
              ))}
            </ul>
          
        </div>
      </div>
    </Layout>
  );
}


export const query = graphql`
query MenuLinkQuery {
  site {
    siteMetadata {
      menuLinks {
        name
        link
      }
    }
  }
}
`;

export default NotFoundPage;
